import { useToast } from "primevue/usetoast";
import {
  Access,
  EAccessMenu,
  RoleModelFragment,
  useUpdateRoleMutation,
} from "@/graphql/types";
import { useValidation } from "vue3-form-validation";
import { DataTableCellEditCompleteEvent } from "primevue/datatable";

export const useUpdateRole = () => {
  const toast = useToast();

  const { form, hasError, validateFields, resetFields } = useValidation({
    name: {
      $value: "",
      $rules: [(n: string) => !n && ""],
    },
    access: {
      $value: [],
      $rules: [
        (n: Access[]) => n?.length !== Object.values(EAccessMenu).length && "",
      ],
    },
  });

  const { loading, mutate, onError, onDone } = useUpdateRoleMutation();

  const reset = ({ name, access }: RoleModelFragment) => {
    resetFields({
      name,
      access: access.map(({ __typename, ...res }) => res),
    });
  };

  onDone(({ data }) => {
    if (data?.updateRole) {
      toast.add({
        summary: "Mise à jour de rôle",
        severity: "success",
        detail: "Rôle mis à jour avec succès !",
        life: 2500,
      });
      reset(data?.updateRole);
    } else {
      toast.add({
        summary: "Mise à jour de rôle",
        severity: "warn",
        detail: "Une erreur est survenue! Réessayez plus tard !",
        life: 2500,
      });
    }
  });

  onError(() => {
    toast.add({
      summary: "Mise à jour de rôle",
      severity: "error",
      detail: "Une erreur est survenue! Réessayez plus tard !",
      life: 2500,
    });
  });

  const onCellEditComplete = ({
    data,
    newData,
    field,
  }: DataTableCellEditCompleteEvent) => {
    data[field] = newData[field];
  };

  const submit = (id: number) => {
    validateFields().then((values) => {
      void mutate({
        input: { ...values, id },
      });
    });
  };

  return {
    loading,
    submit,
    form,
    hasError,
    onCellEditComplete,
    reset,
  };
};
